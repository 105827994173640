<template>
  <div class="display-data">
    <span v-if="label" class="display-data--label">{{ label }}</span>
    <p
      v-if="!$slots.default"
      class="display-data--content"
      :class="{
        empty: !content || !convertNullIfEmpty(content) || empty
      }"
    >
      <template v-if="isLoading">
        <slot name="loading"></slot>
        <p v-if="!$slots.loading" class="skeleton"></p>
      </template>
      <template v-else>
        {{ getContent() }}
      </template>
    </p>
    <img
      v-if="imgURL"
      :src="imgURL"
      :alt="imgAlt"
      class="cursor-pointer"
      style="width: 100px; height: 100px"
      @error="$setAltPhoto"
      @click="imgIndex = 0"
    />
    <div v-if="$slots.default" class="display-data--content">
      <slot></slot>
    </div>
    <span v-if="dotted" class="dotted mt-2"></span>
    <!-- Lightbox -->
    <cool-light-box
      v-if="imgURL"
      :items="[imgURL]"
      :index="imgIndex"
      @close="imgIndex = null"
      :slideshow="false"
      @click.self.stop
    >
    </cool-light-box>
  </div>
</template>

<script>
import { convertNullIfEmpty } from "@/utils/null-check";

export default {
  components: {},
  mixins: [],
  props: {
    label: {
      type: String
    },
    content: {
      type: String
    },
    imgURL: {
      type: String
    },
    imgAlt: {
      type: String
    },
    empty: {
      type: Boolean,
      default: false
    },
    emptyContent: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dotted: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { convertNullIfEmpty, imgIndex: null };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getContent() {
      let dataContent = "";
      if ((this.content || this.imgURL) && !this.empty) {
        dataContent = this.content;
      } else {
        if (this.emptyContent) {
          dataContent = this.emptyContent;
        }
        dataContent = `No ${this.label.toLowerCase()} provided`;
      }
      return dataContent;
    }
  }
};
</script>

<style lang="scss">
.display-data {
  margin-bottom: 16px;
  .display-data--label {
    text-transform: uppercase;
    color: #7a7a7a;
    font-weight: bold;
    font-size: 12px;
  }
  .display-data--content {
    font-weight: normal;
    font-size: 16px;
    color: #333;
    max-width: 100%;

    &.empty {
      font-style: italic;
    }
  }

  .dotted {
    background-image: linear-gradient(
      to right,
      #ddd 50%,
      rgba(255, 255, 255, 0) 10%
    );
    background-position: top;
    background-size: 15px 2px;
    background-repeat: repeat-x;
    height: 2px;
    display: block;
  }
}
</style>
